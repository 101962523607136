export const URL_BASE =
  window.location.hostname === "localhost"
    ? /* ? "http://localhost:3030" */ "https://marthazuccardi-api.e-me.co"
    : "https://marthazuccardi-api.e-me.co";

export const URL_BASE_API =
  window.location.hostname === "localhost"
    ? /* ? "http://localhost:3030" */ "https://marthazuccardi-api.e-me.co"
    : "https://marthazuccardi-api.e-me.co";
export const URL_AUTHENTICATION = "/authentication";

export const DEFAULT_AVATAR_IMAGE = "/images/avatar.png";
export const DEFAULT_IMAGE = "/images/mockup.png";

export const URL_S3_SERVER =
  window.location.hostname === "localhost"
    ? /* ? "http://localhost:3030" */ "https://marthazuccardi-api.e-me.co"
    : "https://marthazuccardi-api.e-me.co";
export const URL_S3 = "https://marthazuccardi.s3.amazonaws.com";

export const BUCKET = "marthazuccardi";
export const ACCESS_KEY_ID = "AKIAUA3FK3BGYGOZ3MOO";
export const SECRET_ACCESS_KEY = "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";
